import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import 'firebase/compat/functions';
import moment from 'moment';
import * as Sentry from '@sentry/browser';
import { intercomInit } from '../variableModules/intercom.module';
import { appcuesInit } from '../variableModules/appcues.module';
import { signInWithEmailAndPassword } from '../variableModules/auth.module';
import { tosCheckFunction } from '../variableModules/tosCheck.module';
import * as coreui from '@coreui/coreui-pro';
window.coreui = coreui;
import Swal from 'sweetalert2';

const firebaseConfig = {
  apiKey: `${import.meta.env.VITE_apiKey}`,
  authDomain: `${import.meta.env.VITE_authDomain}`,
  projectId: `${import.meta.env.VITE_projectId}`,
  storageBucket: `${import.meta.env.VITE_storageBucket}`,
  messagingSenderId: `${import.meta.env.VITE_messagingSenderId}`,
  appId: `${import.meta.env.VITE_appId}`,
  measurementId: `${import.meta.env.VITE_measurementId}`,
};

console.log(firebaseConfig)

var sentryEnabled = true;

if (
  window.location.host.includes('localhost') ||
  window.location.host.includes('127.0.0.1') ||
  window.location.host.includes('.local')
) {
  sentryEnabled = false;
}

firebase.initializeApp(firebaseConfig);
const app = firebase.app();
const auth = firebase.auth();
const db = firebase.firestore();
const firebaseAuth = firebase.auth;
const functions = app.functions('australia-southeast1');
const functionsUS = app.functions('us-central1');
const storage = firebase.storage();
const storageRef = storage.ref();
const FirebaseFieldPath = firebase.firestore.FieldPath;
const FirebaseFieldValue = firebase.firestore.FieldValue;
const FirebaseTimestamp = firebase.firestore.Timestamp;
const publicKey = import.meta.env.VITE_publicKey;

if (
  window.location.host.includes('localhost') ||
  window.location.host.includes('127.0.0.1') ||
  window.location.host.includes('.local')
) {
  functions.useEmulator('localhost', 5001);
}

Sentry.init({
  dsn: import.meta.env.DEV === true ? 'https://5b746c4b18d3d62bbd42c963754fc310@o4506330372702208.ingest.us.sentry.io/4506334421516288' : "https://bf894d60308b3ec204aee45b8c837de3@o4506330372702208.ingest.us.sentry.io/4506697576087552",

  // Alternatively, use `process.env.npm_package_version` for a dynamic release version
  // if your build tool supports it.
  release: 'deiterate@4.0.4',
  integrations: [Sentry.browserTracingIntegration, Sentry.replayIntegration],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    'localhost',
    /^https:\/\/dev-deiterate\.web\.app/,
    'riley.local',
    'macbook.local',
    /^https:\/\/admin\.deit\.dev/,
    /^https:\/\/admin\.deiterate\.com/,
    /^https:\/\/login\.deiterate\.com/,
  ],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,

  enabled: sentryEnabled,
});

window.global ||= window;
window.signInWithEmailAndPassword = signInWithEmailAndPassword;

appcuesInit();
intercomInit();
tosCheckFunction();

function injectBanner() {
  // const banner = document.createElement('div');
  // banner.id = 'dynamic-banner';

  // const style = document.createElement('style');
  // style.innerHTML = `
  //     #dynamic-banner {
  //         display: none; /* Hidden by default */
  //         // position: fixed;
  //         // top: 0;
  //         // left: 0;
  //         width: 100%;
  //         background-color: yellow;
  //         color: black;
  //         text-align: center;
  //         padding: 10px;
  //         z-index: 1000;
  //     }
  // `;

  // document.head.appendChild(style);
  // document.body.prepend(banner);

  function showBanner() {
    const isSmallScreen = window.innerWidth <= 1137; // Adjust the width as needed
    const isFirefox = navigator.userAgent.toLowerCase().includes('firefox');
    const bannerShown = sessionStorage.getItem('bannerShown');

    // Debugging output
    console.log('Banner shown:', bannerShown);

    if (bannerShown === null) {
      let message = '';
      if (isSmallScreen && isFirefox) {
        message = 'de.iterate may not function as expected on small screens in Firefox.';
      } else if (isSmallScreen) {
        message = 'de.iterate may not function as expected on small screens.';
      } else if (isFirefox) {
        message = 'de.iterate may not function as expected in Firefox.';
      }

      if (message) {
        Swal.fire({
          title: 'Warning',
          text: message,
          icon: 'warning',
          confirmButtonText: 'Okay',
        }).then(() => {
          // Store that the banner has been shown
          sessionStorage.setItem('bannerShown', 'true');
        });
      }
    }
  }

  // Run the function on page load and on window resize
  window.addEventListener('load', showBanner);
  window.addEventListener('resize', showBanner);
}

window.addEventListener('DOMContentLoaded', injectBanner);

export {
  firebaseConfig,
  app,
  auth,
  db,
  firebase as firebaseImport,
  functions,
  storage,
  storageRef,
  FirebaseFieldValue,
  FirebaseTimestamp,
  FirebaseFieldPath,
  firebaseAuth,
  moment,
  publicKey,
  functionsUS
};
